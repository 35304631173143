import { http } from "./Config";

const requestPath = "/v1/workapprove";

export const load = (condition) => {
    return http.get(`${requestPath}?userId=${condition.userId || ''}&start=${condition.start || ''}&end=${condition.end || ''}`)
}

export const findByWorkReportId = (id) => {
    return http.get(`${requestPath}/approve/${id}`)
}

export const approve = (id, message) => {
    return http.put(`${requestPath}/approve/${id}?message=${message}`, null, {
        headers: {
            "content-type": "application/json",
        },
    })
}

export const disapprove = (id, message) => {
    return http.put(`${requestPath}/disapprove/${id}?message=${message}`, null, {
        headers: {
            "content-type": "application/json",
        },
    })
}

const exports = {
    load,
    findByWorkReportId,
    approve,
    disapprove,
}

export default exports;
