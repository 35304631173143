import { IconButton } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'

import ConfirmationDialog from './ConfirmationDialog'
import React, { useState, useRef } from 'react'

const requestPath = "/ConstManage/v1/constphotos"

const PhotoDownloader = (props) => {
  const [downloadOpen, setDownloadOpen] = React.useState(false)
  const ref = React.useRef()
  return (
    <React.Fragment>
      <ConfirmationDialog
        dialogTitle="写真データのダウンロード"
        bodyText={(
          <React.Fragment>
            <span>写真データをダウンロードします。</span><br />
            <span>このダウンロードは原寸の写真サイズでダウンロードするため、
              枚数によっては<span style={{ color: "red" }}>大量の通信を行う可能性</span>があります。</span><br />
            <span>本当によろしいですか？</span>
          </React.Fragment>)}
        open={downloadOpen}
        onOk={() => {
          ref.current.click()
          setDownloadOpen(false)
        }}
        onCancel={() => { setDownloadOpen(false) }}
        onClose={() => { setDownloadOpen(false) }}
      />
      <form className="PhotoDownloader"
        action={`${requestPath}/compress?constId=${props.constId}`}
        method="post"
        style={{ display: 'inline' }}
        accept="blob"
        enctype="application/x-www-form-urlencoded"
      >
        <IconButton
          ariaLabel="download"
          fill="none"
          onClick={() => { setDownloadOpen(true) }}
        >
          <DownloadIcon />
        </IconButton>
        <button ref={ref} type="submit" style={{ display: 'none' }} />
        <input style={{ display: 'none' }}
          name="photoIds"
          value={JSON.stringify(props.photoIds)} />
      </form>
    </React.Fragment>
  )
}

export default PhotoDownloader