import { formatDate } from '../utils/Utils'

import './InfoBox.css'
import React from 'react'

const InfoBox = (props) => {

    return (
        <div {...props} className={"InfoBox " + (props.className ? props.className : "")} >
            <div className="Title"><div>{props.title}</div></div>
            <div className="Value">{getDisplayString(props.value ? props.value : props.children)}</div>
        </div>
    )
}

const getDisplayString = (value) => {
    if (!value) {
        return value
    }

    if (typeof (value) !== 'object') {
        return value
    }

    let toString = Object.prototype.toString
    if (toString.call(value) === '[object Date]') {
        value = formatDate(value)
    }
    return value
}

export default InfoBox