import { http } from "./Config";

const requestPath = "/v1/workreports";

export const create = (workData) => {
    return http.post(`${requestPath}`, workData, {
        headers: {
            "content-type": "application/json",
        },
    })
}

export const check = (workData) => {
    return http.post(`${requestPath}/check`, workData, {
        headers: {
            "content-type": "application/json",
        },
    })
}

export const load = (condition) => {
    return http.get(`${requestPath}?userId=${condition.userId || ''}&today=${condition.today || ''}&next=${condition.next || ''}`)
}

export const colors = (condition) => {
    return http.get(`${requestPath}/colors?userId=${condition.userId || ''}&date=${condition.date || ''}`)
}

export const loadById = (id) => {
    return http.get(`${requestPath}/${id}`)
}

const exports = {
    create,
    check,
    load,
    colors,
    loadById,
}

export default exports;
