import { http } from "./Config";

const requestPath = "/v1/reportlist";

export const load = (condition) => {
    return http.get(`${requestPath}?constId=${condition.constId || ''}&start=${condition.start || ''}&end=${condition.end || ''}&userId=${condition.userId || ''}`)
}

export const loadConstReport = (condition) => {
    return http.get(`${requestPath}/const?constId=${condition.constId || ''}&start=${condition.start || ''}&end=${condition.end || ''}&userId=${condition.userId || ''}&area=${condition.area || ''}`)
}

export const loadWorkReport = (condition) => {
    return http.get(`${requestPath}/work?start=${condition.start || ''}&end=${condition.end || ''}&userId=${condition.userId || ''}`)
}

const exports = {
    load, loadConstReport, loadWorkReport
}

export default exports;
