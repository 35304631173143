import './ConstBranchDialog.css'

import { MediaPc, MediaSmartPhone } from '../../common/Responsive'

import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Button, Typography, } from '@mui/material'
import { Table, TableHead, TableBody, TableCell, TableRow, Th } from '@mui/material'
const ConstBranchDialog = (props) => {
  return (
    <Dialog
      className="ConstBranchDialog"
      disableEscapeKeyDown={!props.canCancel}
      fullWidth={true}
      maxWidth={"sm"}
      open={props.open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && props.onClose) {
          props.onClose()
        }
      }}
      ariaLabelledby="form-dialog-title" >
      <DialogTitle id="form-dialog-title" >枝番選択</DialogTitle>
      <DialogContent >
        <Typography><span>複数の枝番があります。</span><span>枝番を選択して下さい。</span></Typography>
        <Table className={"dataList"}>
          <TableHead className={"scrollHead"}>
            <TableRow>
              <TableCell className={"constId"} style={{ textAlign: "center" }}>工事番号＋枝番</TableCell>
              <MediaPc>
                <TableCell className={"name"} style={{ textAlign: "center" }}>工事名</TableCell>
              </MediaPc>
            </TableRow>
          </TableHead>
          <TableBody className={"scrollBody"} >
            {
              props.value.map(row => (
                <TableRow className={"ConstListRow"} key={row.constId} onClick={() => {
                  if (props.onChoice) {
                    props.onChoice(row)
                  }
                }}>
                  <TableCell style={{ display: "flex", flexDirection: "column" }}>
                    <div>{row.constId + (row.branchNo ? ("-" + ("" + row.branchNo).padStart(2, '0')) : "")}</div>
                    <MediaSmartPhone>
                      <div>{row.constName}</div>
                    </MediaSmartPhone>

                  </TableCell>
                  <MediaPc>
                    <TableCell>
                      <div>{row.constName}</div>
                    </TableCell>
                  </MediaPc>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </DialogContent>
      {
        (props.canCancel) ? (
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                if (props.onClose) {
                  props.onClose()
                }
              }} >
              キャンセル
            </Button>
          </DialogActions>
        ) : (
          <React.Fragment />
        )
      }
    </Dialog >
  )

}

export default ConstBranchDialog