import './SearchCondition.css'
import InfoBox from '../../common/InfoBox'
import CellInput from '../../common/CellInput'
import UserChoicer from '../../common/UserChoicer'

import { userBufferData } from '../../hooks/UserBufferState'

import React, { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import {
  Button,
  Checkbox,
  FormControlLabel,
} from '@mui/material'

const SearchCondition = (props) => {
  const userBuffer = useRecoilValue(userBufferData)
  const [searchCondition, setSearchCondition] = useState(props.value)

  useEffect(() => {
    setSearchCondition(props.value)
  }, [props.value])

  return (
    < div className="SearchCondition" >
      <InfoBox title={"検索文字列"}>
        <CellInput className="searchString" key={"searchKey"} type="text" value={searchCondition.searchKey}
          onChange={(event) => {
            setSearchCondition({ ...searchCondition, searchKey: event.target.value })
          }}
        />
      </InfoBox>
      <FormControlLabel className="process" style={{ whiteSpace: "nowrap" }}
        control={<Checkbox color="secondary" size="small" style={{ marginLeft: "10px" }} checked={searchCondition.process} onChange={(event) => {
          setSearchCondition({ ...searchCondition, process: event.target.checked })
        }} />}
        label="進行中の工事のみ表示"
      />
      <InfoBox className="salesStaff" title={"営業担当"}>
        <UserChoicer value={searchCondition.userId}
          onChoiceUser={(userId) => {
            setSearchCondition({ ...searchCondition, salesStaff: userBuffer.get(userId) ? userBuffer.get(userId) : "" })
          }} />
      </InfoBox>

      <InfoBox className="constStaff" style={{ marginLeft: "5px" }} title={"工事担当"}>
        <UserChoicer value={searchCondition.userId}
          onChoiceUser={(userId) => {
            setSearchCondition({ ...searchCondition, constStaff: userBuffer.get(userId) ? userBuffer.get(userId) : "" })
          }} />
      </InfoBox>
      <Button
        style={{ marginLeft: "10px" }}
        variant="contained"
        color="inherit"
        onClick={() => {
          props.onChangeSearchCondition(searchCondition)
        }} >
        検索
      </Button>
    </div >
  )
}

export default SearchCondition