import { http } from "./Config";

const requestPath = "/v1/constdiscussion";

export const load = (condition) => {
    return http.get(`${requestPath}?constId=${condition.constId || ''}&start=${condition.start || ''}&end=${condition.end || ''}`)
}

export const loadDiscussion = (id) => {
    return http.get(`${requestPath}/discussion/${id}`)
}

export const loadByConstReportId = (constReportId) => {
    return http.get(`${requestPath}/constreport/${constReportId}`)
}

export const addDiscussion = (id, message) => {
    return http.put(`${requestPath}/discussion/${id}?message=${message}`, null, {
        headers: {
            "content-type": "application/json",
        },
    })
}

const exports = {
    load,
    loadDiscussion,
    loadByConstReportId,
    addDiscussion,
}

export default exports;
