import './ConstListGrid.css'
import ConstPanel from '../common/ConstPanel'

import React from 'react'

const ConstListGrid = (props) => {
  const constList = props.constList

  return (
    <div className="ConstListGrid">
      {
        // constMapのvalueを一覧で表示する
        props.constList.map((v) => {
          return (<ConstPanel selected={v.constId === props.constId} key={v.constId} value={v} onChoiceConstId={(constId) => {
            props.onChoiceConstId(constId)
          }} />)
        })
      }
    </div>
  )
}

export default ConstListGrid