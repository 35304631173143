import { http } from "./Config"
const requestPath = "/v1/excel"

/**
 * Excelファイルを作成してダウンロードする
 * @param  templateName テンプレート名 stage
 * @param  data テンプレート変数として利用するデータ
 */
const download = (templateName, data) => {
  return http.post(`${requestPath}/${templateName}`, data, {
    headers: {
      "content-type": "application/json",
      "accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    },
    responseType: "blob"
  })
    .then(
      response => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        })

        //レスポンスヘッダからファイル名を取得
        let fileName = getFileName(response.headers["content-disposition"])
        if (!fileName) {
          fileName = templateName + ".xlsx"; // デフォルト値
        }

        const a = document.createElement('a')
        a.href = URL.createObjectURL(blob)
        a.download = fileName
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(a.href)  // メモリリークを防ぐ
      },
      error => {
        console.error("Download failed", error)
        throw error
      }
    )
}

const getFileName = (contentDisposition) => {
  if (!contentDisposition) {
    return null
  }

  let fileName

  // filename*=UTF-8''の場合
  const filenameRegex = /filename\*=UTF-8''([\w%.-]+)/i
  const matches = filenameRegex.exec(contentDisposition)
  if (matches != null && matches[1]) {
    fileName = decodeURIComponent(matches[1])
  } else {
    // filename=の場合
    const filenameRegex = /filename="?(.+)"?/i
    const matches = filenameRegex.exec(contentDisposition)
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, '')
      // 必要に応じてデコード
      try {
        fileName = decodeURIComponent(escape(fileName))
      } catch (e) {
        console.warn("Failed to decode filename", e)
      }
    }
  }

  return fileName
}

const exports = {
  download,
};

export default exports;
