import './ConstReportThumbnail.css'

import { thumbUrl } from '../../../services/Config'

import React from 'react'

const ConstReportThumbnail = (props) => {
    const size = (props.size ? props.size : "s")
    const srcS = thumbUrl + "/s/?photoId=" + props.value._id
    const srcM = thumbUrl + "/m/?photoId=" + props.value._id
    const srcL = thumbUrl + "/l/?photoId=" + props.value._id

    const src = size === "s" ? srcS : size === "m" ? srcM : srcL

    const pickupPhoto = (event) => {
        if (props.onClickPicture) {
            props.onClickPicture(props.index)
        }
        event.stopPropagation()
    }

    const events = {
        // クリック：全画面での写真表示
        onClick: pickupPhoto,
    }

    return (
        <React.Fragment>
            <span className="ConstReportThumbnail" key={props.key}
                {...events}
            >
                <img
                    id={"constPhotos-" + props.value._id}
                    key={props.value._id}
                    src={src}
                    alt=""
                    ariaDescribedby={"p" + props.id}
                    loading={"lazy"}
                    decoding={"async"}
                    fetchPriority={"low"}
                />
            </span>
        </React.Fragment>
    )
}

export default ConstReportThumbnail