import './ResultManage.css'

import Header from './common/Header'
import ResultManageFooter from './result/ResultManageFooter'
import Content from './common/Content'
import MainContent from './common/MainContent'
import ConstListController from './result/ConstListController'
import ConstManageChoicer from './result/selected/ConstManageChoicer'

import { loadListType, saveListType } from './result/condition/ListType'

import React, { useState, useEffect } from 'react'
import { Transition } from 'react-transition-group'

const ResultManage = (props) => {
    const [displayConst, setDisplayConst] = useState(false)
    const [listType, setListType] = useState(loadListType())
    const [constId, setConstId] = useState("")

    useEffect(() => {
        saveListType(listType)
    }, [listType])

    const transitionStyles = {
        entering: { left: '-100%', transition: 'all 300ms ease' },
        entered: { left: '-100%' },
        exiting: { left: '0%', transition: 'all 300ms ease' },
        exited: { left: '0%' },
    }

    return (
        <Content>
            <Header title="工事情報" />
            <Transition in={displayConst} timeout={1500}>
                {(state) => (
                    <MainContent style={{ width: "200%", ...transitionStyles[state] }}>
                        <ConstListController listType={listType} constId={constId} onChangeConstId={(constId) => {
                            // 一瞬、選んだ工事にフォーカスが当たったことを見せてから進捗表に遷移する
                            setConstId(constId)
                            setTimeout(() => {
                                setDisplayConst(true)
                            }, 100);
                        }} onChangeListType={(listType) => setListType(listType)} props={props} />
                        {displayConst ?
                            <ConstManageChoicer constId={constId} />
                            : null}
                    </MainContent>
                )}
            </Transition>
            <ResultManageFooter constId={constId} listType={listType} backButton={displayConst} onBack={() => setDisplayConst(false)} />
        </Content >
    )
}

export default ResultManage
