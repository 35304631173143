const colors = new Map()

colors.set("工事進行中", "lightgreen")
colors.set("工事完了", "lightblue")
colors.set("工番取得", "plum")
colors.set("欠番", "darksalmon") // 欠番は茶色
colors.set("着工前", "yellow")
colors.set("失注", "pink")

const ConstStatus = (props) => {
  const color = getColor(props.value)
  return (
    <div className="ConstStatus Process" style={{ backgroundColor: color, /*角丸にする*/ borderRadius: "4px", padding: "5px" }}>{props.value}</div>
  )
}

export const getColor = (value) => {
  return colors.get(value)
}

export default ConstStatus
