import './ConstListController.css'

import { MediaPc } from '../common/Responsive'

import Footer from '../common/Footer'
import ConfirmationDialog from '../common/ConfirmationDialog'
import { ROLES } from '../utils/Utils'

import ProjectService from '../../services/ProjectService'
import ExcelService from '../../services/ExcelService'

import { resultSaveState } from '../hooks/ResultSaveState'
import { resultOutputExcelState } from '../hooks/ResultOutputExcelState'
import { resultImportCsvState } from '../hooks/ResultImportCsvState'
import { resultExportCsvState } from '../hooks/ResultExportCsvState'
import { laborCostsSaveState } from '../hooks/LaborCostsSaveState'
import { resultTabIndex } from '../hooks/ResultTabIndex'
import { messageState } from '../hooks/MessageState'
import { errorState } from '../hooks/ErrorState'

import { sessionUserState } from '../hooks/SessionUserState'

import { Box, Button } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import DownloadIcon from '@mui/icons-material/Download'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'

import { useRecoilValue, useSetRecoilState } from 'recoil'

import React, { useState, useEffect, useRef } from 'react'
import ConstBranchDialog from './footer/ConstBranchDialog'

const requestPath = "/ConstManage/v1/constphotos"

const ResultManageFooter = (props) => {
  const setSave = useSetRecoilState(resultSaveState)
  const setOutputExcel = useSetRecoilState(resultOutputExcelState)
  const setImportCsv = useSetRecoilState(resultImportCsvState)
  const setExportCsv = useSetRecoilState(resultExportCsvState)
  const setLaborCostsSave = useSetRecoilState(laborCostsSaveState)
  const tabIndex = useRecoilValue(resultTabIndex)
  const userData = useRecoilValue(sessionUserState)
  const setMessage = useSetRecoilState(messageState) // メッセージ表示用
  const setError = useSetRecoilState(errorState)
  const [constByBranch, setConstByBranch] = useState([])
  const [reportControl, setReportControl] = useState({ reportName: '', open: false }) // 枝番選択ダイアログ
  const roles = userData.roles

  useEffect(() => {
    if (props.constId) {
      ProjectService.bybranch(props.constId)
        .then(result => {
          setConstByBranch(result.data.objects)
        }, error => {
          setMessage({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
        })
        .catch(error => {
          setMessage({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
        })
    }
  }, [props.constId])

  const downloadOrderReport = () => {
    if (constByBranch.length === 1) {
      ExcelService.download("orderReport", { constId: constByBranch[0].constId, branchNumber: constByBranch[0].branchNo })
        .catch(error => {
          setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
        })
    } else {
      setReportControl({ reportName: 'orderReport', open: true })
    }
  }

  const downloadFinancialReport = () => {
    if (constByBranch.length === 1) {
      constByBranch.forEach((rec) => {
        ExcelService.download("financialReport", { constId: rec.constId, branchNumber: rec.branchNo })
          .catch(error => {
            setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
          })
      })
    } else {
      setReportControl({ reportName: 'financialReport', open: true })
    }
  }

  const ButtonStagePlan = () => {
    return (
      <React.Fragment >
        <Box style={{ paddingRight: "10px" }}>
          <Button variant="contained" color="secondary" onClick={(event) => {
            setSave(true)
          }}><SaveAltIcon /><MediaPc>保存</MediaPc></Button>
        </Box>
        <Box style={{ paddingRight: "10px" }}>
          <Button variant="contained" color="primary" onClick={(event) => {
            setOutputExcel(true)
          }}><FontAwesomeIcon icon={faFileExcel} size="lg" /><MediaPc>&nbsp;Ｅｘｃｅｌ出力</MediaPc></Button>
        </Box>
        <Box style={{ paddingRight: "10px" }}>
          <Button color="inherit" variant="contained" onClick={(event) => {
            setImportCsv(true)
          }}
          ><FileUploadIcon /><MediaPc>インポート</MediaPc></Button>
        </Box>
        <Box style={{ paddingRight: "10px" }}>
          <Button color="inherit" variant="contained" onClick={(event) => {
            setExportCsv(true)
          }}
          ><FileDownloadIcon /><MediaPc>エクスポート</MediaPc></Button>
        </Box>
      </React.Fragment>
    )
  }

  const ButtonLaborCosts = () => {
    return (
      <Box style={{ paddingRight: "10px" }}>
        <Button variant="contained" color="secondary" onClick={(event) => {
          setLaborCostsSave(true)
        }}><SaveAltIcon /><MediaPc>保存</MediaPc></Button>
      </Box>
    )
  }

  const ButtonBack = () => {
    return (
      <Box style={{ paddingRight: "10px" }}>
        <Button color="inherit" variant="contained" onClick={(event) => {
          props.onBack()
        }}
        ><ArrowBackIcon /><MediaPc>戻る</MediaPc></Button>
      </Box>
    )
  }

  const ButtonConstInfo = () => {
    return (
      <React.Fragment>
        {
          roles.includes(ROLES.ORDER_REPORT) ? (
            <Box style={{ paddingRight: "10px" }}>
              <Button color="primary" variant="contained" onClick={(event) => {
                downloadOrderReport()
              }}
              >受<MediaPc>注報告書</MediaPc></Button>
            </Box>
          ) : (<React.Fragment />)
        }
        {
          roles.includes(ROLES.FINANCIAL_REPORT) ? (
            <Box style={{ paddingRight: "10px" }}>
              <Button color="secondary" variant="contained" onClick={(event) => {
                downloadFinancialReport()
              }}
              >収<MediaPc>支明細書</MediaPc></Button>
            </Box>
          ) : (<React.Fragment />)
        }
      </React.Fragment>
    )
  }

  const ButtonPhoto = () => {
    const [save, setSave] = useState(false)
    const ref = useRef(null)

    return (
      <div className="AlertDialog">
        <ConfirmationDialog
          dialogTitle="写真データのダウンロード"
          bodyText={(
            <React.Fragment>
              <span>写真データをダウンロードします。</span><br />
              <span>このダウンロードは原寸の写真サイズでダウンロードするため、
                枚数によっては<span style={{ color: "red" }}>大量の通信を行う可能性</span>があります。</span><br />
              <span>本当によろしいですか？</span>
            </React.Fragment>)}
          open={save}
          onOk={() => {
            ref.current.click()
            setSave(false)
          }}
          onCancel={() => { setSave(false) }}
          onClose={() => { setSave(false) }}
        />
        <Box style={{ paddingRight: "10px" }}>
          <form method="post"
            action={`${requestPath}/compress?constId=${props.constId}`}
            style={{ display: 'inline' }}
            accept="blob"
          >
            <button type="submit" ref={ref} style={{ display: 'none' }} />

            <Button variant="contained" color="primary"
              fill="none"
              onClick={(event) => {
                setSave(true)
              }}
            ><DownloadIcon /><MediaPc>ダウンロード</MediaPc></Button>
          </form>
        </Box>
      </div>
    )
  }

  return (
    <React.Fragment>
      <ConstBranchDialog
        open={reportControl.open}
        value={constByBranch}
        canCancel={true}
        onChoice={(row) => {
          ExcelService.download(reportControl.reportName, { constId: row.constId, branchNumber: row.branchNo })
            .catch(error => {
              setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
          setReportControl({ open: false })
        }}
        onClose={() => {
          setReportControl({ open: false })
        }}
      />

      <Footer className="ResultManageFooter">
        {props.backButton ? (<ButtonBack />) : null}
        {props.backButton && tabIndex === 0 ? (<ButtonConstInfo />) : null}
        {props.backButton && (tabIndex === 1 || tabIndex === 2) ? (<ButtonStagePlan />) : null}
        {props.backButton && tabIndex === 5 ? (<ButtonLaborCosts />) : null}
        {props.backButton && tabIndex === 3 ? (<ButtonPhoto />) : null}
      </Footer>
    </React.Fragment>
  )
}

export default ResultManageFooter