import './ConstPanel.css'

import ConstStatus, {getColor} from '../../common/ConstStatus'
import {MediaPc, MediaSmartPhone} from '../../common/Responsive'

import { formatDate } from '../../utils/Utils'

import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Tooltip,
} from '@mui/material'

import React from 'react'

const ConstPanel = (props) => {
  let imgPath = "/ConstManage/v1/constphotos/projectPhoto?constId=" + props.value.constId

  return (
    <Card id={'ANCHOR-' + props.value.constId} className="ConstPanel" style={props.selected ? { backgroundColor: "#cfc" } : {}}>
      <CardActionArea component="div" onClick={() => {
        props.onChoiceConstId(props.value.constId)
      }}>
        <a href={"#" + props.value.constId} />
        <CardMedia className="ConstImage" image={imgPath} alt="image" />
        <CardContent>
          <MediaPc>
            <div className="ConstId">{props.value.constId}</div>
          </MediaPc>
          <MediaSmartPhone>
            <div className="ConstId" style={{color: "black", backgroundColor: getColor(props.value.process), opacity: 0.7}}>{props.value.constId}</div>
          </MediaSmartPhone>
          <Tooltip title={props.value.name}>
            <div className="ConstName">{props.value.name}</div>
          </Tooltip>
          <hr style={{ marginTop: "2px", marginBottom: "4px" }} />
          <div className="ConstTerm">{formatDate(props.value.termFrom)}　〜　{formatDate(props.value.termTo)}</div>
          <div style={{ display: "flex", marginTop: "5px" }}>
            <div className="SalesStaff">
              <div className="Label">営業担当</div>
              <div className="Name">{props.value.salesStaff}</div>
            </div>
            <div className="ConstructionStaff">
              <div className="Label">工事担当</div>
              <div className="Name">{props.value.staff}</div>
            </div>
          </div>
          <ConstStatus className="ConstStatus" value={props.value.process} />
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default ConstPanel