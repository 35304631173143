import './ConstListController.css'
import MainContent from '../common/MainContent'
import SearchCondition from './condition/SearchCondition'
import ListTypeSelector from './condition/ListTypeSelector'
import ConstListGrid from './grid/ConstListGrid'
import ConstList from './list/ConstList'
import { userBufferData } from '../hooks/UserBufferState'

import { constListData } from '../hooks/ConstListData'
import { sessionUserState } from '../hooks/SessionUserState'

import React, { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'

const ConstListController = (props) => {
  const userBuffer = useRecoilValue(userBufferData)
  const initialKeyData = { searchKey: '', process: true, salesStaff: '', constStaff: '' }
  const [keyData, setKeyData] = useState(initialKeyData)

  const [constList, setConstList] = useState([])

  const constMap = useRecoilValue(constListData) // 工事情報データ（マップ構造）
  const userState = useRecoilValue(sessionUserState)

  useEffect(() => {
    setConstList(getConsts())
  }, [constMap, keyData])

  // 画面で入力された検索条件により、工事を絞り込む
  const getConsts = () => {
    let userList = getUsers(keyData.searchKey)
    let list = Array.from(constMap.values()).filter((v) => {
      console.log(v)
      return (v.constId.includes(keyData.searchKey)
        || v.name.includes(keyData.searchKey)
        || userList.includes(v.salesStaff)
        || userList.includes(v.constStaff))
        && (v.constId.startsWith("O-") || v.constId.startsWith("T-"))
        && ((userState.personalSetting?.area === "大阪" && v.constId.startsWith("O"))
          || (userState.personalSetting?.area === "東京" && v.constId.startsWith("T"))
          || userState.personalSetting?.area === "指定無し")
        && (keyData.salesStaff ? v.salesStaff === keyData.salesStaff : true)
        && (keyData.constStaff ? v.staff === keyData.constStaff : true)
        && (keyData.process ? v.process === "工事進行中" : true)
    })
    return list
  }

  const getUsers = (name) => {
    let userList = []

    if (userBuffer) {
      userBuffer.forEach((v, k) => {
        if (v && v.includes(name)) {
          userList.push(v)
        }
      })
    }
    return userList
  }

  const mainView = () => {
    switch (props.listType) {
      case "grid":
        return (
          <ConstListGrid constList={constList} constId={props.constId} onChoiceConstId={(constId) => {
            props.onChangeConstId(constId)
          }} />
        )
      case "list":
        return (
          <ConstList constList={constList} constId={props.constId} onChoiceConstId={(constId) => {
            props.onChangeConstId(constId)
          }} />
        )
    }
  }

  return (
    <div className="ConstListController" style={props.style}>
      <div className="constHeader">
        <SearchCondition value={keyData} style={{ flexShrink: "300px", flexGrow: 1 }} onChangeSearchCondition={(condition) => {
          setKeyData(condition)
        }} />
        <ListTypeSelector value={props.listType} style={{ flexBasis: "200px" }}
          onChangeListType={(listType) => {
            props.onChangeListType(listType)
          }} />
      </div>
      {mainView()}
    </div>
  )
}

export default ConstListController
