import './ConstList.css'

import ConstListRow from './ConstListRow'
import { visuallyHidden } from '@mui/utils'

import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
} from '@mui/material'

import React, { useEffect } from 'react'

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const headCells = [
  { id: 'constId', numeric: false, disablePadding: true, label: "工番" },
  { id: 'name', numeric: false, disablePadding: true, label: "工事名称" },
  { id: 'termFrom', numeric: false, disablePadding: true, label: "工期" },
  { id: 'salesStaff', numeric: false, disablePadding: true, label: "営業担当" },
  { id: 'staff', numeric: false, disablePadding: true, label: "工事担当" },
]

const ConstList = (props) => {
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('constId')
  const [constList, setConstList] = React.useState(props.constList)

  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  useEffect(() => {
    setConstList(stableSort(props.constList, getComparator(order, orderBy)))
  }, [props.constList, order, orderBy])

  return (
    <div className="ConstList">
      <TableContainer className="ConstListSelector" sx={{ maxHeight: "calc(100% - 50px)" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow style={{ backgroundColor: "white" }}>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  className={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              // constMapのvalueを一覧で表示する
              constList.map((v) => {
                return (<ConstListRow key={v.constId} value={v} selected={v.constId === props.constId} onChoiceConstId={(constId) => {
                  props.onChoiceConstId(constId)
                }} />)
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ConstList