import { http } from "./Config"
const requestPath = "/v1/pdf"

/**
 * pdfを作成してダウンロードする
 * @param  templateName テンプレート名 workreport もしくは constreport
 * @param  data テンプレート変数として利用するデータ
 */
const download = (templateName, data) => {
  if (window.matchMedia && window.matchMedia('(max-device-width: 480px)').matches) {
    return downloadSmartPhone(templateName, data)
  } else {
    return downloadPc(templateName, data)
  }
}

const downloadSmartPhone = (templateName, data) => {
  return http.post(`${requestPath}/${templateName}`, data, {
    headers: {
      "content-type": "application/json",
      "accept": "application/pdf"
    },
    responseType: "blob",
  })
    .then(
      response => {
        const blob = new Blob([response.data], {
          type: "application/pdf"
        });

        var url = URL.createObjectURL(blob)

        var a = document.createElement("a")
        document.body.appendChild(a)

        a.style = "display: none"
        a.href = url
        a.click()

        // 重要: 使用後にリソースを解放
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      },
      error => {
        throw error;
      }
    )
}

const downloadPc = (templateName, data) => {
  const wait = async (ms) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, ms)
    })
  }

  const setWindowSetting = async (w, url) => {
    return new Promise((resolve) => {
      // ウィンドウが完全に読み込まれるまで待機
      w.addEventListener('load', () => {
        // 画面クローズの時に、生成したＵＲＬを削除
        w.onbeforeunload = () => {
          URL.revokeObjectURL(url)
        }

        // 印刷ダイアログ表示
        w.print()
        resolve()
      })
    })
  }

  return http.post(`${requestPath}/${templateName}`, data, {
    headers: {
      "content-type": "application/json",
      "accept": "application/pdf"
    },
    responseType: "blob",
  })
    .then(async (response) => {
      const blob = new Blob([response.data], {
        type: "application/pdf"
      });

      const url = URL.createObjectURL(blob)
      const w = window.open(url)

      if (w) {
        await setWindowSetting(w, url)
      } else {
        console.error('ポップアップがブロックされました')
        URL.revokeObjectURL(url)
      }
    })
    .catch(error => {
      throw error;
    })
}

const exports = {
  download,
};

export default exports;
