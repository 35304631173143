import { isSmartPhone } from './Responsive'
import React, { useState, useEffect } from 'react'
import { normalize } from '@geolonia/normalize-japanese-addresses'
import { Icon } from 'leaflet'
import markerIcon from 'leaflet/dist/images/marker-icon.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'

const defaultIcon = new Icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41]
})

const Map = (props) => {
  const [location, setLocation] = useState(null)
  useEffect(() => {
    if (props.address) {
      normalize(props.address).then(result => {
        if (result && result.point) {
          let location = [result.point.lat, result.point.lng]
          setLocation(location)
        } else {
          setLocation(null)
        }
      })
    }
  }, [props.address])

  return (
    <div className="Map">
      {location ? (
        <MapContainer style={props.style} dragging={!isSmartPhone()} center={location} zoom={15} scrollWheelZoom={false}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker
            position={location}
            icon={defaultIcon}
          >
            <Popup>
              <a href={`https://www.openstreetmap.org/?mlat=${location[0]}&mlon=${location[1]}&zoom=17`} target="_blank" rel="noopener noreferrer">
                {props.address}
              </a>
            </Popup>
          </Marker>
        </MapContainer>
      ) : (
        <div style={props.style} />
      )
      }
    </div >
  )
}

export default Map
