import React from 'react'

export const STATUS = {
    EDITTING: 0,    // 作成中
    HOLDING: 1,     // 提出（勤怠未確認保留中）
    SENDED: 2,      // 提出
    APPROVAL: 3,    // 承認
    DISAPPROVAL: 9,     // 否認
}

export const MEDIA_SMART_PHONE = "screen and (max-width: 480px) and (orientation:portrait)"
export const MEDIA_PC = "screen and (min-width: 481px)"

export const ROLES = {
    SYSTEM_ADMINISTRATOR: "systemAdministrator",
    WORK_REPORT_REQUIRED: "workReportRequired",
    ADMINISTRATOR: "administrator", // 上長（承認画面系の権限）
    CONST_ADMINISTRATOR: "constAdministrator",  // 工事進捗責任者（収支系情報の閲覧権限）
    FINANCIAL_STATEMENTS: "financialStatements", // 現場収支表の閲覧権限
    LABOR_COSTS: "laborCosts", // 人件費の閲覧権限
    CONSTREPORT_PRINT: "constReportPrint",
    WORKREPORT_PRINT: "workReportPrint",

    FINANCIAL_REPORT: "USER_BALANCESTATEMENT",
    ORDER_REPORT: "USER_ORDERREPORT",
    EMPLOYEE: "USER_EMPLOYEE",
}

export const STATUS_NAME = {
    0: "作成中",
    1: "提出（勤怠未確認保留中）",
    2: "提出",
    3: "承認処理中",
    9: "否認",
}

/*
*テキストを見た目の長さでカットする
*/
export const cutText = (text, maxLength) => {
    let result = ''
    let currentLength = 0

    // 半角カナの正規表現
    const halfWidthKanaRegex = /[\uff61-\uff9f]/;

    for (let i = 0; i < text.length; i++) {
        const char = text[i]
        let charLength = 0

        // 半角カナの場合は1文字としてカウント
        if (halfWidthKanaRegex.test(char)) {
            charLength = 1
        } else {
            charLength = /[\u0000-\u00ff]/.test(char) ? 1 : 2
        }

        if (currentLength + charLength > maxLength) {
            break
        }

        result += char
        currentLength += charLength
    }

    return result
}
export const truncateTime = (date) => {
    if (date) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }
    return date
}

export const getStatusName = (status) => {
    let name = STATUS_NAME[status]
    if (!name) {
        name = "−"
    }
    return name
}

export const formatNumber = (num) => {
    if (!num) {
        return ''
    }
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const formatRate = (num) => {
    if (!num) {
        return ''
    }

    return '' + (num * 100).toFixed(1) + '%'
}

export const calcRate = (value, base) => {
    if (!base) {
        return 0
    }

    return (value / base)
}

export const convMongoIsoDate = (dt) => {
    let isoDate = convIsoDate(dt)
    return `ISODate("${isoDate}")`
}

export const convIsoDate = (dt) => {
    let y = dt.getFullYear()
    let m = ("00" + (dt.getMonth() + 1)).slice(-2)
    let d = ("00" + dt.getDate()).slice(-2)
    return `${y}-${m}-${d}T00:00:00.000+09:00`
}

export const getNow = () => {
    var dt = new Date()
    var y = dt.getFullYear()
    var m = ("00" + (dt.getMonth() + 1)).slice(-2)
    var d = ("00" + dt.getDate()).slice(-2)
    var result = y + "-" + m + "-" + d
    return result
}

export const getToday = () => {
    let now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
}

export const formatDate = (dt) => {
    if (!dt) {
        return ""
    }
    if (typeof (dt) === 'string' || typeof (dt) === 'number') {
        dt = new Date(dt)
        if (!dt) {
            return ""
        }
    }
    try {
        let y = dt.getFullYear()
        let m = ("00" + (dt.getMonth() + 1)).slice(-2)
        let d = ("00" + dt.getDate()).slice(-2)
        let idx = dt.getDay()
        let w = '日月火水木金土'[idx]
        return (
            <span>
                {y + "/" + m + "/" + d}
                (<span style={(idx === 0 || idx === 6) ? { color: "#FF3F3F" } : {}}>{w}</span>)
            </span>
        )
    } catch (e) {
        console.log(e)
        return ""
    }
}

export const parseDate = (input) => {
    if (input instanceof Date) {
        return input
    }

    if (typeof input === 'string') {
        const parsedDate = new Date(input);
        if (!isNaN(parsedDate.getTime())) {
            return parsedDate
        }
    }

    throw new Error('Invalid input: must be a Date object or a valid date string');
}

export const isValidDate = (text) => {
    if (!/^\d{1,4}(\/|-)\d{1,2}\1\d{1,2}$/.test(text)) {
        return false;
    }

    const [year, month, day] = text.split(/\/|-/).map(v => parseInt(v, 10));

    return year >= 1900
        && (1 <= month && month <= 12)
        && (1 <= day && day <= daysInMonth(year, month));

    function daysInMonth(year, month) {
        if (month === 2 && isLeapYear(year)) {
            return 29;
        }

        return {
            1: 31, 2: 28, 3: 31, 4: 30,
            5: 31, 6: 30, 7: 31, 8: 31,
            9: 30, 10: 31, 11: 30, 12: 31
        }[month];
    }

    function isLeapYear(year) {
        return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
    }
}

export const getInitialWorkReport = (initial, size) => {
    let times = new Array(0)
    for (let idx = 0; idx < size; idx++) {
        times.push(getInitialWorkRow(initial + idx)
        )
    }
    const objects = {
        times: times,
        dates: { today: null, next: null }
    }
    return objects
}

export const getInitialWorkRow = (time) => {
    return {
        time: time,
        today: {
            text: '',
            const: {
                constId: '',
                name: ''
            }
        },
        next: {
            text: '',
            const: {
                constId: '',
                name: ''
            }
        },
    }
}

export const getInitialConstReport = (date) => {
    return {
        date: date, // 対象日付
        workDetail: '', // 作業内容
        safetyInspection: [], // 安全点検・確認項目
        riskPrediction: [],// 危険予知活動
        actionForRisk: [], // 危険から身を守るための行動項目
        workingRange: '',   // 作業範囲
        vehicle: '',    // 使用車両
        dedura: [],  // 作業人員（出面）
        juki: [], // 重機
        safetyGoal: '', // 今月の安全宣言
        introspection: '',  // 作業内容の反省
        nearMiss: '',   // ヒヤリハット
        constructionPeriod: { envTargetPeriod: null, customerApprovalPeriod: null },
        progress: 0,   // 進捗状況
        check: { date: null, matter: '' },    // 関所日
        instruction: '',    // 指示内容と報告
        remarks: '', // 備考
    }
}

export const getInitialPersonalSetting = (userId) => {
    return {
        userId: userId,
        // workSuperiorId: null,
        // constSuperiorId: null,
        bulkUpdate: false,
        area: null,
        memoryConstId: 5,
        guidanceNotice: true,
        workReportErrorNotice: true,
        workReportDisapproveNotice: true,
        constReportCommentNotice: true,
    }
}

// 案件マスタの工種から、工種大分類マスタの工種大分類を確定するマップ
export const constTypeMap = [
    { type: "DEMOLITION", name: "解体" },
    { type: "ASBESTOS", name: "石綿除去" },
    { type: "DIOXIN", name: "ダイオキシン" },
    { type: "PLUCKING", name: "杭抜き" },
    { type: "ARCHITECTURE", name: "建築・改修工事" },
    { type: "CIVIL", name: "土木・舗装工事" },
    { type: "SOIL", name: "土壌汚染対策工事" },
    { type: "INVESTIGATION", name: "調査" },
    { type: "MISC", name: "雑工事" },
    { type: "PCB", name: "ＰＣＢ処理" },
    { type: "OTHER", name: "その他" },
]
